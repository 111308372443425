@import "coloroPalette";

.timer{
  color: $brand_color_var1_3;
  margin-bottom: 40px;
}

.timer__items {
  display: flex;
  justify-content: center;
  font-size: 72px;
}
.timer__item {
  position: relative;
  min-width: 90px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 15px;
  text-align: center;
}
.timer__item::before {
  content: attr(data-title);
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 14px;
}
.timer__item:not(:last-child)::after {
  content: ':';
  position: absolute;
  right: -15px;
}

@media (max-width: 600px) {
  .timer {
    &__items {

      font-size: 58px;

    }

    &__item {
      min-width: 48px;
    }
  }

}

@media (max-width: 600px) {
  .timer {
    &__items {

      font-size: 48px;

    }
  }

}

@media (max-width: 375px) {
  .timer {
    &__items {

      font-size: 40px;

    }

    &__item {
      min-width: 40px;
    }
  }

}
