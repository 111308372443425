@import "../../node_modules/normalize.css";
@import "coloroPalette";

@import "form.css";

@import url('https://rsms.me/inter/inter.css');


//Open Sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


//@font-face {
//  font-family: "Open Sans";
//  font-style: normal;
//  font-weight: 600;
//  /* Браузер сначала попробует найти шрифт локально */
//  src: local("Open Sans"),
//    /* Если не получилось, загрузит */
//  url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
//}




/*Обнуляющие стили*/

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  //padding: 0;
}

/* Убираем внешние отступы */
body,
h1, h2, h3, h4,
p,
ul[class], ol[class], li,
figure, figcaption, blockquote,
dl, dd {
  //margin: 0;
}
h1{
  //margin: 0;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html{
}

html, body{
  min-height: 100vh;
  //width: 100%;
  //height: 100%;
  //overflow-x: hidden;
  //font-size: 12px;

  background-color: #fafafa;
}

body._lock {
  //overflow-x: unset;
  overflow: hidden;
}

/* Выставляем основные настройки по-умолчанию для body */
.landing {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  margin: 0;

  font-size: 18px;
  //font-family: "Source Sans Pro", sans-serif;
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $brand_color_var1_1;
  line-height: 1.55;

  background-color: $brand_color_var1_6;

}

body._lock{
  overflow-x: hidden;
}

.wrapper{
  width: 100%;
  height: 100%;
  //background-color: $brand_color_var1_3;

  &._lock{
    overflow-y: hidden;
  }
}



.h1{
  font-size: 3.5em;
  //text-align: left;
  //text-transform: uppercase;
  line-height: 1.2;
  font-weight: 600;

  &__be{
    text-transform: lowercase;
    padding-left: 275px;
  }

  &__verb{
    font-size: 1.5em;
    color: $brand_color_var1_2;
    display: block;
    padding-left: 100px;
    text-shadow: 1px 1px 2px $brand_color_var1_1;

  }

  &__conclusion{
    color: $brand_color_var1_2;
    text-shadow: 1px 1px 2px $brand_color_var1_1;

  }
}

.h2{
  position: relative;

  font-size: 3em;
  text-align: center;
  //text-transform: uppercase;
  line-height: 1.05;
  font-weight: 500;
  margin-top: 0 ;
  margin-bottom: 10px;



}

.h3{
  font-size: 3.5em;
  text-align: left;
  text-transform: uppercase;
  line-height: 1.05;
  font-weight: 500;

}




.header{
  padding: 0 40px 0 0;


  //position: fixed;
  position: relative;
  z-index: 10;


  &__layout{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__nav{


    //position: fixed;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    //max-width: 1200px;
    z-index: 3;
    //margin-bottom: 1.25rem;

  }

  &__inner{
    z-index: 2;
    min-height: 100vh;
    //color: $brand_color_var1_3;
    padding: 0 3rem;
    //padding-top: 6rem;
    //padding-top: 62px;

    //justify-content: flex-start;
    //font-size: 24px;
    //min-height: 40rem;
    //max-height: 50rem;
  }


  &__title{
    box-sizing: border-box;
    padding-top: 1.25rem;
    font-size: 2.5rem;
    margin: 3rem 0 0;
    //width: 100%;
    //max-width: 900px;
    //margin: 0 auto;
    //box-sizing: border-box;
  }

  &__h1{
    margin: 0;
    font-size: 3em;
  }

  &__text{
    font-size: 3rem;
    padding: 0 3rem 3rem;
  }




  &__footer{
    background: rgba(1,1,1,0.5);
    border-radius: 0 0 80px 80px;
    //width: 100%;
    padding: 1rem;
    max-width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
  }


  &_fullwidth{
    width: 100%;
  }

}





.nav{
  //background-color: $brand_color_var1_1;

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;


    max-width: 1200px;
    width: 100%;
  }

  &__list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    font-size: 1em;

    &_vertical{
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
    }
  }
  &__element{
    white-space: nowrap;
    margin: 0 1rem;
    user-select: none;
    cursor: pointer;
    color: $brand_color_var1_2;

    &:hover{
      //background: rgba(255,255,255,0.5);
      color: $brand_color_var1_2;
    }

    &_vertical{
      margin: 0;
      text-align: center;
    }

    &_size-medium{
      font-size: 2em;
    }
  }

  &__link{

    outline: none;
    text-decoration: none;
    color: $brand_color_var1_3;



    &:link{
      //color: $brand_color_var1_2;
      color: $brand_color_var1_3;
    }

    &:visited{
      color: $brand_color_var1_3;
      //color: $brand_color_var1_4;
    }
    &:focus{}
    &:hover{

      color: $brand_color_var1_2;
      transition: all .5s ease;
    }
    &:active{}


    &_main{
      color: $brand_color_var1_4;

      &:link{
        //color: $brand_color_var1_2;
        color: $brand_color_var1_4;
      }

      &:visited{
        color: $brand_color_var1_3;
        //color: $brand_color_var1_4;
      }
      &:focus{}
      &:hover{

        color: $brand_color_var1_2;
        transition: all .5s ease;
      }
    }
  }

  &__logo{
    width: 250px;
    height: 100px;
    //background-color: $brand_color_var1_2;
    padding: 5px;
    flex-shrink: 0;

    margin-left: 20px;
    display: inline-block;


  }
}




.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  &__img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__link{

    color:$brand_color_var1_4;


    &:link{
      //color:$brand_color_var1_2;
      font-weight: bold;
    }
    &:visited{
      //color:$brand_color_var1_2;
    }
    &:focus{}
    &:hover{}
    &:active{}
  }

  &__image{


  }

  &__icon{
    //background-image: url("../assets/images/logo_white.png");
    //background-size: cover;
    //background-color: #793ab9;
    //border-radius:  10px;
    //height: 100px;
    //width: 100px;
    cursor: pointer;

  }

  &__text{
    user-select: none;
    font-size: 1.8rem;
    cursor: pointer;
    //font-weight: 200;
    color: $brand_color_var1_2;
    //text-shadow:  0 0 5px #ffffff, 1px 0 10px #ffffff, 1px 0 20px $brand_color_var4_2;
    //color: #383838;


  }

  &_mobile{
    display: none;
  }

}

.hamburger{
  display: none;


}

.content {
  //padding-top: 100px ;
  //font-size: 18px;

  &__inner{

  }

}


.section {
  //background-color: $brand_color_var1_1;

  &_first{
    background-color: #2b2b2b;
    //height: calc(100vh - 100px);

    & > .section__inner{
      //padding-left: 0;
      //padding-right: 0;
    }

  }

  &__wrapper{

    &_dark{
      //background-color: rgba(20,18,15,.7);
      background-image: -webkit-linear-gradient(top, rgba(20,18,15,0.70), rgba(20,18,15,0.80));
    }

    &_light{
      background-image: -webkit-linear-gradient(top, rgba(20,18,15,0.3), rgba(20,18,15,0.5));
    }

    &_dark-to-light{
      //background-color: rgba(20,18,15,.7);
      background: linear-gradient($brand_color_var1_1 10%, rgba(20,18,15,0.7) 50%, rgba(20,18,15,0) 100%);


      & .section__title {

        &:after {
          background-color: $brand_color_var1_4;
        }
      }
    }

    &_light-to-dark{
      //background-color: rgba(20,18,15,.7);
      background: linear-gradient(rgba(20,18,15,0) 20%, $brand_color_var1_1 100%);


      & .section__title {

        &:after {
          background-color: $brand_color_var1_4;
        }
      }
    }

    &_orange{
      background-color: $brand_color_var1_4;
      position: relative;
    }

    &_mbeans{
      background: url("../assets/icons/coffee-beans_l.png");
      background-size: 100px;
      background-repeat: repeat;

    }

    &_beans{
      background: url("../assets/icons/coffee-beans_l.png");
      background-size: contain;
      //filter: invert(73%) sepia(55%) saturate(408%) hue-rotate(111deg) brightness(82%) contrast(99%);

    }




  }

  &__accent{
    position: absolute;
    transform: scaleX(-1);
    bottom: 0;
    width: 200px;
    right: 50px;
    z-index: 0;
    filter: invert(31%) sepia(77%) saturate(3509%) hue-rotate(17deg) brightness(89%) contrast(84%);

  }

  &__header{
    margin-bottom: 70px;
    text-align: center;

  }

  &__title{
    position: relative;
    max-width: 50%;
    text-align: center;
    font-size: 1em ;
    margin-bottom: 60px;

    &:after{
      display: block;
      content: "";
      position:absolute;
      //bottom: 0;

      top: calc(100% + 5px);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 2px;
      background-color: $brand_color_var1_1;
    }

    &_full{
      max-width: unset;
    }


  }
  &_dark &__title{

    &:after{
      background-color: $brand_color_var1_4;
    }
  }


  &__img-main{
    margin-right: 40px;
    border: 2px solid $brand_color_var1_2;
    max-width: 512px;
  }

  &__img-centered{
    display: block;
    margin: 0 auto;
    border: 2px solid $brand_color_var1_2;
  }

  &__img-left{
    display: inline-block;
    height: 500px;
    border: 2px solid $brand_color_var1_2;
  }

  &__sub-title{
    text-align: center;
    margin: 20px auto;
    //max-width: 480px;

    &_upper{
      text-transform: uppercase;
    }

    &_dark{
      color: rgba(0,0,0,0.5);

    }

  }

  &__layout{
    //background: url("../assets/images/logo_white.png");
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__wallpaper{
    &_1{
      background: url("../assets/images/wallp_1.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

    }
    &_2{
      background: url("../assets/images/coffee-beans-circle2.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

    }

    &_3{
      background: url("../assets/images/coffee-beans-circle2.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

    }
  }


  &__inner{
    position: relative;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 40px;
    z-index: 2;

    &_row{
      display: flex;
      overflow: hidden;
      flex-direction: row;

    }



    &_col{
      display: flex;
      overflow: hidden;
      flex-direction: column;

    }
  }

  &_flex{
    display: flex;
  }

  &__body{
    width: 100%;
    margin-top: 20px;

    &_row{
      display: flex;
      flex-direction: row;
    }

    &_row-rev{
      display: flex;
      flex-direction: row-reverse;
    }

    &_jusi{
      justify-content: space-between;

    }


    ul{
      padding-left: 30px ;

    }
  }


  &__text{
    font-size: 1.2em;



  }

  &__row{
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    //gap: 20px;
    //height: 300px;
    margin-bottom: 20px;
    margin-top: 20px;

    &_reverse{
      flex-direction: row-reverse;
    }



    &_small{
      flex: 1;
      overflow: hidden;

    }



    &_big{
      flex: 2;
      overflow: hidden;
    }
    &_hight{
      min-height: 300px;
    }


  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__col_mobile{

  }

  &__map{

    width: 100%;
    max-height: 180px;
    box-sizing: content-box;

  }

  &__img-gal_large{
    width: 100%;
    position: relative;
    top:-50px

  }

  &__img-gal_small{
    width: 100%;
  }



  &__img_in-text{

  }

  &_dark{
    background: $brand_color_var1_1;
    color: $brand_color_var1_3;

  }

  &_centered{
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  &_v-centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  &_mid-height{
    min-height: 400px;

  }

  &_hight-height{
    min-height: 800px;
  }

  &_light-text{
    color: $brand_color_var1_3;
  }

  &_no-line-height{
    line-height: 0;
  }

  &_flex-col{
    display: flex;
    flex-direction: column;
  }

}

.row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

}


.text{

  &_justify{
    text-align: justify;
  }

  &_italic{
    font-style: italic;

  }

  &__sign{
    display: block;
    text-align: right;
    font-style: normal;

  }

  &__row{
    margin: 0;

  }

  &_nowrap{
    white-space: nowrap;

  }
}


.header-section{
  //background: #fdfdfe;
  min-height: 20px;
  padding: 100px 0;
  text-align: right;
  max-width: unset;
}



.first-screen{
  background: url("../assets/images/first-screen.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  //background-color: black;

  &__wrapper{
    //background-color: rgba(20,18,15,.7);
    background-image: -webkit-linear-gradient(top, rgba(20,18,15,0.70), rgba(20,18,15,0.80));
  }



  &__title{
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);


  }

  &__h1{
    position: relative;
    text-align: left;
    //display: inline-block;
    z-index: 2;
    max-width: 800px;
    //margin: 0 auto;
  }

  &__descr{
    text-align: left;
    font-size: 1.1em;
    max-width: 800px;
    //margin: 0 auto;
    //margin-left: 20px;
    //padding-right: 20px;
  }

  &__img-container{
    position: absolute;
    right: 0;
    top:65%;
    transform: translateY(-50%);

    width: 450px;
    //height: 250px;
    z-index: 1;

  }


  &__img{
    width: 100%;

  }


  &__inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $brand_color_var1_3;

    //position: relative;
    //top:40%;
    //transform: translateY(-50%);
    min-height: 750px;

  }
}

.price{
  display: flex;
  justify-content: space-between;

  gap: 2em;

  color: $brand_color_var1_3;

  &_rev{
    flex-direction: row-reverse;
  }

  &__inner{


  }

  &__descr{

    display: inline-block;
    font-size: 1.2em;
    line-height: 1.2;

    vertical-align: top;
    text-align: unset;

    &_al-left{
      text-align: left;

    }
  }



}


.project{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

  color: $brand_color_var1_3;


  &_reverse{
    flex-direction: row-reverse;

  }

  &__title{
    margin: 0;
    font-size: 2em;
    text-align: center;
    color: $brand_color_var1_2;


  }

  &__inner{

    &_nearImg{
      max-width: 60%;
    }


  }

  &__descr {
    //margin: 0 auto;

    display: inline-block;
   //width: 40%;
   font-size: 1.2em;
    line-height: 1.2;

   vertical-align: top;
  text-align: unset;

    &_al-left{
      text-align: left;

    }

  }

  &__img{
    //position: absolute;
    //left: 0;
    //top: 0;
    //width: 100%;
    //height: auto;
    object-fit: cover;
    width: 40%;
    margin-left: 30px;
    //height: 100px;

    &_left{
      margin-right: 30px;
      margin-left: unset;
    }

  }
  &__img-wrapper{
    //position: relative;
    //padding-top: 56.25%; /* 16:9 Aspect Ratio */
    //width: 100%;
  }

}
.advantage{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em;

  &__item{
    display: flex;
    flex-basis: 48%;
    gap: 2em;


  }
  &__icon{
    flex-shrink: 1;
    flex-basis: 100px;
    position: relative;
    min-width: 100px;
    height: 100px;
    padding: 20px;

    border-radius: 50%;
    border: 2px solid $brand_color_var1_2;

    & > img{
      //position: absolute;
      display: block;

      // color from css filters https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(73%) sepia(55%) saturate(408%) hue-rotate(111deg) brightness(82%) contrast(99%);

      //left: 0;
      //top: 0;
      width: 100%;
      height: auto;

      color: $brand_color_var1_2;

    }

  }
  &__descr{

  }
  &__title{
    font-size: 1.5em;
    font-weight: bold;

  }
  &__list{
    margin: 0;
    list-style: outside !important;

    //list-style-image: url();
    //list-style: circle;

  }
  &__point{
    font-size: 0.9em;


  }

}

.fit{
  flex-wrap: wrap;
  //background-color: rgba(0,0,0,.8);
  font-size: 20px;
  color: $brand_color_var1_3;


  &__item{

    padding: 20px 20px;


    text-align: center;
  }

  &__header{
    text-align: center;
    line-height: 1;
    font-weight: bold;

    font-size: 2em;


  }

  &__title{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5em;
  }

  &__descr{
    font-size: 0.7em;

  }
  &__sub-text{
    margin: 20px 0;

    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    color: $brand_color_var1_4;




  }

  &_third{
    flex: 1 0 33%;

  }

  &_single{
    max-width: 400px;
    margin: 0 auto;
    justify-content: center;
  }

}


.step-line{
  counter-reset: num;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;


  &:after{
    content:"";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $brand_color_var1_2;

  }
}

.step{
  //display: none;
  position: relative;
  user-select: none;
  flex:1;
  flex-wrap: nowrap;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;




  &:nth-child(2n){
    justify-content: flex-end;
  }

  &__img-cont{
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 3em;
    color: $brand_color_var1_3;
    background: $brand_color_var1_2;

    width: 100px;
    height: 100px;
    margin: 20px 0;
    box-sizing: border-box;
    border: 2px solid $brand_color_var1_2;
    border-radius: 50%;
    //overflow: hidden;

    &:before{
      counter-increment: num;
      content:"" counter(num);

      position: absolute;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      z-index: 2;
      color: $brand_color_var1_3;
    }

    &:after{
      content:"";
      position: absolute;
      display: inline-block;
      bottom: -20px;
      //left: 50%;
      //transform: translateX(-50%);

      z-index: 10;
      width: 4px;
      height: 20px;
      background: $brand_color_var1_2;
    }

  }
  &__img{
    object-fit: cover;
    width: 100%;
    height: 100%;

  }

  &__text{
    text-align: center;
    font-size: 0.8em;
    line-height: 0.9;



  }


}

.gallery{
  display: flex;
  flex-wrap: wrap;
  gap: 1em;


  &__item{
    flex: 1 0 30%;

    position: relative;

    display: flex;
    justify-content: center;
    //align-items: center;

    //height: 100%;
    //min-height: 300px;
    width: 100%;
    overflow: hidden;
    //padding: 56.25%;



  }
  &__img{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;

    &_medium{
      width: 80%;
    }

  }

}

.certificates{


  &__item{
    flex: 1;
    display: flex;
    justify-content: center;
    //width: 100%;
    height: 100%;

  }
  &__link{

  }
  &__img{
    object-fit: cover;
    max-width: 300px;
    width: 100%;
    height: 100%;
    //height: 180px;

  }
}

.contacts{
  //justify-content: space-around;
  gap: 1em;
  &__text{
    font-size: 1.5em;
    margin: 0 0 10px;
  }

  &__link{
    display: inline-block;
    width: fit-content;
    font-size: 1.5em;
    text-decoration: none;
    color: $brand_color_var1_3;
    margin-bottom: 10px;



    &:hover{
      //color: $brand_color_var1_2;
      color: $brand_color_var1_4;
      transition: all .5s ease;

    }
  }

  &__block{
    flex: 1;
    display: flex;
    flex-direction: column;

    //width: 50%;



  }

}

.text{
  &__icon{
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 10px;

    &_1{
      background: url("../assets/icons/icon_1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &_2{
      background: url("../assets/icons/icon_2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &_3{
      background: url("../assets/icons/icon_3.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.footer{
  padding: 0 40px;

  &__col{
    width: 15%;
  }

  &__subtitle{
    color: $brand_color_var1_2;
    font-size: 1.2em;
    margin-bottom: .5em;
    text-align: center;
  }

  &__inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }

  &__icons_row{
    text-align: center;


  }


  &__contacts{


  }
}

.img{
  position: absolute;
  object-fit: cover;
  //left: 50%;
  //transform: translateX(-50%);
  top: 0;
  left: 0;
  //min-width: 270px;
  //height: 100%;
  //width: auto;

  width: auto;
  height: 100%;
  max-width: 100%;

  &_mirror{
    transform: scaleX(-1);
  }


  &-container{
    overflow: hidden;
    position: relative;
    //min-width: 40%;
    min-height: 500px;
    min-width: 300px;

    &_show-tablet{
      display: none;
    }


  }
}

/**********************UI************************/

.button{
  display: inline-block;
  padding: 10px 20px;
  //border-radius: 4px;
  //border: 1px solid #cccccc;
  border: none;
  color: $brand_color_var1_3;
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:focus{
    outline: none
  }
  &:active{
    box-shadow: inset 2px 2px 1px rgba(0,0,0,.3);
  }
  &:disabled{
    background-color: #ccc;
    color: #000000;
    cursor: not-allowed;
  }

  &_big{
    padding: 20px 40px;
    min-width: 400px;
    min-height: 80px;


  }

  &_main{
    background-color: $brand_color_var1_4;

    &:hover{
      background-color: $brand_color_var1_4_hover;
    }
  }

  &_sub{
    background-color: $brand_color_var1_6;
    color: $brand_color_var1_1;

    &:hover{
      background-color: $brand_color_var1_3;
    }
  }

  &_wide{

  }

}


body._scrolled{
  .up_button{
    display: block;

    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease 0s;
  }
}

.up_button{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);


  position: fixed;
  right: 5%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  background-color: rgba(255,133,98,0.6);
  //border-radius: 50%;
  z-index: 10;
  cursor: pointer;

  //box-shadow: 0 4px 0 $brand_color_var1_2;

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease 0s;

  &:hover{
    //background-color: #0283bb;
    background-color: $brand_color_var1_4;
  }

  &:active {
    //box-shadow: 0 2px 0 $brand_color_var1_2;
  }

  .up_button_bar{
    position: relative;
    top: 20%;
    left: calc(50%);
    display: block;
    height: 100%;
    width: 2px;
    //transform: scale(0);
    //transform: rotate(-45deg);
    &::before{
      content:"";
      position: absolute;
      left: 8px;
      height: 50%;
      width: 3px;
      background: $brand_color_var1_3;
      transform: rotate(-45deg);
      border-radius: 1px;
    }
    &::after{
      content:"";
      position: absolute;
      right: 8px;
      height: 50%;
      width: 3px;
      background: $brand_color_var1_3;
      transform: rotate(45deg);
      border-radius: 1px;
    }
  }
}






/*==================RESPONSIVE======================*/
/*Диапазоны*/
/*0-479px*/
/*480 - 767px*/
/*768 - 991px*/
/*992 - 1199px*/
/*1200px +*/
/*==========  Desktop First Method  ==========*/


@media screen and (max-width: 1200px) {

  .section {
    //background-color: #59ac6c;
  }
  
}

@media screen and (max-width: 1024px) {

  .nav__element{
    margin-right: 0 ;

  }
  .nav__link_main{
    display: none;
  }

  .section {
    //background-color: #59a0ac;

    &__title{
      max-width: unset;
    }

  }

}

@media screen and (max-width: 992px) {


  .section {
    //background-color: #59a0ac;

    &__title{
      margin-bottom: 30px;
    }

    &__inner{
      padding: 20px 40px;
      gap: 2em;
    }

    &__img-main{
      width: 60%;
      margin: 0;

    }
    &__row{
      overflow: hidden;

    }

    &__row_small{
      overflow: hidden;

    }
    &__img-gal_small{
      position: relative;
      left: unset;
    }

    &__img-gal_large{
      //width: unset;
      position: relative;
      top: unset;
      //left: -50%;
    }

    &__img-left{
      height: 350px;

    }

    &__col_tablet-wide{
      flex-direction: column;
      align-items: center;
    }

    &__accent{
      width: 150px;
      right: 20px;


    }

  }

  .first-screen{

    &__title{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    &__h1{
      font-size: 4.5em;
      text-align: center;
      margin-bottom: 0;
    }


    &__inner{
      padding: 80px 0;
      align-items: center;

      flex-direction: column;
      top:unset;
      transform: unset;
    }

    &__img-container{
      position: relative;
      //width: 100%;
      width: 60%;
      top:50%;
      transform: unset;
    }

    &__descr{
      padding-left: 20px;
      padding-right: 20px;
    }
  }



  .content{
    font-size: 18px;

  }

  .advantage{
    gap:1em;

    &__item{
      gap: unset;
      position: relative;
      flex-direction: column;
      align-items: center;
    }

    &__title{
      text-align: center;
    }
    &__icon{
      flex-basis: 60px;
      min-width: 60px;
      width: 60px;
      height: 60px;
      padding: 5px;

    }
  }



  .step{
    &__img-cont{
      width: 80px;
      height: 80px;
    }
  }

  .h2,.h3{
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    //margin: 0;
  }

  .inner_revert{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

  }

  .project {



    &__title {
      line-height: 1.2em;


    }

    &__img{
      max-width: 50%;

    }

    &__descr {
      line-height: 1.2;

    }
  }

}

@media screen and (max-width: 768px) {

  .nav_closed{

    .nav__list_main{
      padding: 0;
      transition: all .5s ease;

    }
  }


  .nav__link_main{

    display: unset;

  }

  .nav__content{
    justify-content: center;
    //background-color: $brand_color_var1_1;

  }
  .nav__list_main {
    padding: 20px 0 0;
    margin: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 100px;
    width: 100%;
    //background-color: $brand_color_var1_1;
    background-image: -webkit-linear-gradient(top, rgba(20,18,15,0), $brand_color_var1_1 5%);


    transition: 1s top ease;

    & > .nav__element{
      z-index: 1;
      display: block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0;
      height: 50px;
      transition: all 1s ease;

      &:hover{
        background-color: #2f2e33;

        .nav__link{
          width: 100%;
          color: $brand_color_var1_2;
          transition: all .5s ease;

        }
      }

      &_closed{
        padding: 0;
        height: 0;
        font-size: 0;
        transition: all .5s ease;


      }


    }
  }

  .nav__list_closed{
    top: 50px;
    //padding: 0;
    transition: 1s top ease;
  }

  .hamburger{
    display: block;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &__icon{
      font-size: 2em;
      color: $brand_color_var1_2;
    }
  }




  .first-screen{
    //margin-bottom: 40px;
    &__inner{
      min-height: unset;

    }
  }




  .section {
    //background-color: #aca559;

    &__inner{
      gap: unset;
    }

    &__img-centered{
      width: 100%;
    }

    &__img-main{
      width: 80%;
      margin: 0;

    }

    &__title{
      margin-bottom: 30px;
    }

    &__col_tablet{
      flex-direction: column;
    }

    &__accent {
      width: 100px;
      right: 10px;
    }
  }

  .project{

  }

  .step-line{


    flex-direction: column;
    align-items: flex-start;

    &:after{
      content:"";
      position: absolute;
      left: 0;
      height: 100%;
      width: 4px;
      background: $brand_color_var1_2;

    }


  }

  .step{
    flex-direction: row;
    gap: 1em;

    &:nth-child(2n){
      //flex-direction: row-reverse;
      //justify-content: unset;


    }

    &__img-cont{
      margin:  10px 0 10px 20px;
      min-width: 100px;
      height: 100px;

      &:after{
        content:"";
        position: absolute;
        display: inline-block;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);

        z-index: 10;
        height: 4px;
        width: 20px;
        background: $brand_color_var1_2;
      }
    }

    &__text{
      text-align: left;
      font-size: 1em;
    }
  }

  .h1{
    font-size: 3em;
    width: 100%;
    //text-align: center;

    &__verb{
      padding-left: 10%;

    }

    &__be{
      padding: 0;
      float: right;
      margin-right: 200px;

    }

    &__conclusion{
      position: absolute;
      right: 0;

    }


  }

  .img{
    left: 50%;
    transform: translateX(-50%) scaleX(-1);
    &-container{
      margin-bottom: 20px;
      min-height: 350px;
      width: 100%;

      &_show-tablet{
        float: right;
        right: 0;
      }

    }
  }

  .price{
    gap: 0;
     &_tablet-col{
         flex-direction: column;
     }
  }

  .project{

    &__inner{
      margin-bottom: 2em;

      &_no-margin-bot{
        margin-bottom: 0;
      }

    }

    &__title{
      line-height: 1;

    }

    &__descr{
      font-size: 1.2em;
    }

    &__img{
      max-width: unset;
      width: 80%;
      margin-bottom: 20px;
      margin-left: unset;
      align-self: center;
      //width: 280px;
    }

  }

  .gallery{
    &__item{
      flex: 1 0 45%;

      &_hide{
        display: none;

      }
    }


    &__img_medium{
      width: 100%;

    }



  }

  .contacts{
    &__block{
      width: unset;
    }
  }

  .footer{
    &__inner{
      flex-direction: column;
      align-items: center;
    }
    &__col{
      text-align: center;
      width: 100%;
      margin-bottom: 1em;

    }
  }



}

@media (orientation: landscape) and (max-width: 1024px) {



  .section_hight-height{
    min-height: unset;

  }
  .first-screen__img-container{
    display: none;
  }


}

@media (max-width: 600px) {






  .h1 {
    font-size: 2.5em;

    &__be{
      margin-right: 165px;
    }

  }

  .h3, .h2{
    font-size: 2.5em;

  }
  .first-screen{
    &__inner{
      padding-right: 40px;
      padding-left: 40px;
      //transform: translateY(-50%);
    }
  }

  .nav{
    &__list{
      //padding: 0;
    }
  }
  .project{
    flex-direction: column;
    justify-content: flex-start;
    gap: 1em;




    &__descr{
      margin-bottom: 0;

    }

    &__img{
      //width: 220px;
    }


  }

  .mob_col{
    flex-direction: column;
    gap: 1em;

  }

  .text{
    margin: 20px 0;
  }

  .section{
    &__title{
      margin-bottom: 0;
    }
    &__body{
      margin-bottom: 20px;
    }
    &__row{
      //flex-direction: column;
      //height: 460px;
      //justify-content: unset;
      //margin-bottom: unset;
      &_small{
        flex: 2;
      }



    }

    &__accent{
      display: none;
    }

    &__text{
      font-size: 1em;
      line-height: 1.2em;
    }

    &_hight-height{
      min-height:  unset;
    }
  }

  .section__img-gal_small {
    left: unset;
    //top: -50%;

  }
  .section__img-gal_large{
    top: 50%;
    transform: translateY(-50%);
  }

  .section__row_big{
    flex: unset;
  }

  .section__row_small{
    flex: unset;
  }

  .project{
    &__img{
      width: 100%;

    }
  }

  .fit{
    &__item{
      padding: 0;
    }
  }

  .advantage{
    justify-content: center;
    &__item{
      flex-basis: unset;


    }
  }

  .contacts{
    &__block{
      text-align: center;
    }
  }

  .button{
    margin-right: unset;
  }




}

@media (max-width: 480px) {
  html, body{
    overflow-x: hidden;
  }

  .h1{
    font-size: 2em;

    &__be{
      margin-right: 135px;
    }

  }

  .logo_desktop{
    display: none;
  }

  .logo_mobile{
    display: inline-block;
  }

  .nav{
    &__logo{
      width: 100px;
      height: 100px;
    }

  }

  .section {

    &__no-marbot-mobile{
      margin-bottom: 0;

    }
    &__inner{
      padding: 20px;

      &_row{
        //flex-direction: column;
      }
    }

    &__col_mobile{
      flex-direction: column;
    }
  }

  .first-screen{
    &__inner{

      padding-top: 80px;
      padding-bottom: 80px;
    }
    &__descr{
      font-size: 1em;
    }
  }

  .step{
    &__img-cont{
      min-width: 80px;
      height: 80px;

    }
  }

  .gallery{
    &__item{
      flex: 1 0 100%
    }
  }

  .contacts{
    &__block{
      margin-bottom: 20px;
    }
  }



  .footer{

    &__inner{
      flex-direction: column;
    }

    &__col{
      margin-bottom: 1.5em;

    }
  }

}

@media (max-width: 480px) {
  .section{
    &__inner{

    }

    &__img-left{
      height: 250px;
    }
  }

  .content{
    font-size: 18px;
  }

  .h1{
    &__verb{
      padding: 0;

    }
  }
  .footer{
    &__inner{
      padding: 20px 0;
    }
  }
}

@media (max-width: 480px) {
  .h1{
    margin-top: 30px;
  }

  .content{
    font-size: 16px;
  }

  .fit{
    &__header{
      font-size: 1.8em;
      margin-top: 0;
    }
  }

}


@media not screen and (orientation:landscape) {
.section{
  &_hight-height{
    min-height:  unset;
  }
}


}



.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.hide{
  display: none !important;
}


