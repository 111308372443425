.popup{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  //overflow-y: auto;
  //overflow-x: hidden;

  transition: all 0.8s ease 0s;

}
.popup._open {
  opacity: 1;
  visibility: visible;

  .popup_content {
    transform: perspective(600px) translate(0px, 0%) rotate(0deg);
    opacity: 1;
  }
}

.popup_body{
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}

.popup_content{
  background-color: #f1f1f1;
  color: #000;
  max-width: 800px;
  padding: 30px;
  position: relative;
}

.popup_close{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #000000;
   text-decoration: none;
}
.popup_title{
  color: #2F2E33FF;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 3rem;

  //margin: 0 0 1em 0;

}

.popup_text{}

.popup_img{
  width: 100%;
}



.popup{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  z-index: 10;

  opacity: 0;
  visibility: hidden;

  overflow-y: auto;
  overflow-x: hidden;

  transition: all 0.8s ease 0s;

  &__block{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    flex: 1;


  }

  &__img-cont{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    //border: 1px solid black;
    overflow: hidden;
    position: relative;

  }

  &__img{
    width: auto;
    height: 100%;
    position: absolute;
    right: 0;
  }

  &__descr{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;

    text-align: center;
    line-height: 0.9;
    color: #2f2e33;
    padding: 10px 20px;
    background-color: #eeeeee;
    border-radius: 20px;


    &-pic{
      display: inline-block;
      width: 50px;
      height: 50px;
      float: right;
    }
  }

  &_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2em;
  }

}
.popup._open {
  opacity: 1;
  visibility: visible;

  .popup_content {
    transform: perspective(600px) translate(0px, 0%) rotate(0deg);
    opacity: 1;
  }
}

.popup_body{
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}

.popup_content{
  background-color: white;
  color: #000;
  max-width: 1024px;
  padding: 30px;
  position: relative;

  border-radius: 20px;
  box-shadow: 2px 2px 2px #7e7e80;
}

.popup_close{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
}
.popup_title{

  font-size: 36px;
  margin: 0 0 1em 0;

}

.popup_text{}

.popup_img{
  width: 100%;
}

@media (max-width: 768px) {

  .popup{

    &__block{

    }


    &__sub-block{
      flex: 0.5;
      justify-content: end;
    }

    &__descr{
      flex-direction: column-reverse;
      gap: 0.2em;
      &-pic{
        float: none;
      }
      &-text{
        margin-top: 0;
      }
    }

    &_row{
      gap: 0.5em;
    }

    &__img-cont{
      width: 220px;
      height: 220px;
    }
  }
}

@media (max-width: 600px) {
  .popup{
    &_row{
      flex-direction: column;
    }
    &__block{
      flex-direction: row;
      justify-content: center;
    }

    &__img-cont{
      display: none;
    }
    &__descr{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

}


@media (max-width: 480px) {

  .popup_content{
    max-width: 400px;
    width: 100%;

  }


}