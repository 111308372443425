@import "coloroPalette";


#container{
  overflow: hidden;
  min-height: 300px;
  display: flex;
  position: relative;
}
.carousel-control > img{
  //margin-top: 252px;
}
.carousel-control{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: $brand_color_var1_4;
  color:#ffffff;
  border-radius: 50%;
  text-decoration: none;
  //height: 600px;
  //position: absolute;
  font-size: 2em;

  &:focus{
    color: #ffffff;
  }
  &:hover{
    background-color: $brand_color_var1_4_hover;

  }

}
.carousel > div:not(.active__item){

}
.next{
  float: right;
}
.prev{
  float: left;
}
.carousel{
  flex: 1;
  //width: 80%;
  //float: left;
  //border: 1px solid;

  &__layout{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;

  }

  &__title{
    display: block;

  }

  &__nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    align-items: center;

  }
  &__element{
    position: absolute;
    width: 100%;
    transition: left 1.5s ease-in-out;

    &_first{
      z-index: 10;
    }

    .active__item{
      left: 0;
      transition: left 1.5s ease-in-out;


    }

    &_scale{
      //scale: 1.5;
      min-width: 2000px;
    }
  }

  &__img{
    //position: absolute;
    //object-fit: contain;
    //width: 2048px;
    //height: 512px;

    position: absolute;
    left: 0;
    top: 0;
    //height: 100%;
    //width: auto;

    width: 100%;
    height: auto;
    //margin: 0 auto;

  }
}
#circles {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  list-style:none;
  z-index: 5;
  padding: 0;
}
#circles li {
  display:inline-block;
  border-radius:15px;
  width:15px;
  height:15px;
  margin: 0 0 10px 10px;
  border:2px solid $brand_color_var1_4;
  background: $brand_color_var1_3;
  cursor:pointer;
}
#circles li.active {
  border:2px solid $brand_color_var1_3;
  background:$brand_color_var1_4;
}

.slide{
  display: flex;
  justify-content: center;

  &_centered{

  }

  &__inner{

    padding: 50px 0;
    max-width: 800px;

    &_row{
      display: flex;
      flex-direction: row;
      gap: 2em;


    }
  }
  &__img-cont{
    position: relative;
    display: inline-block;
    min-width: 100px;
    height: 100px;
    margin: 0 0 20px;
    box-sizing: border-box;
    border: 2px solid $brand_color_var1_2;
    border-radius: 50%;
    overflow: hidden;

  }
  &__img{
    position: absolute;

    top: 0;
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);

    //object-fit: contain;

  }

  &__descr{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__text{
    margin-bottom: 10px;

  }

  &__sign{
    font-weight: bold;
  }

}

@media screen and (max-width: 992px) {
  .slide {

    &__inner {
      padding: 20px 0;
      max-width: 600px;
    }

  }
}

@media (max-width: 600px) {

  .slide{
    &__inner{
      padding: 0;
    }
    &__text{
      line-height: 1.2em;
    }
  }

  #container{
    min-height: 300px;

  }

  #circles{
    margin: 0;
  }

}


@media (max-width: 600px) {
  .slide{
    &__inner{
      &_row{
        gap: 1em
      }
    }
  }

}


@media (max-width: 480px) {

  #container{
    //min-height: 250px;

  }

  .slide{
    &__img-cont{
      width: 100px;
    }

    &__inner{
      &_row{
        flex-direction: column;
        align-items: center;
        gap: 0
      }
    }
    &__img-cont{
      margin: 0;
    }
    &__descr{
      flex-direction: column-reverse;
    }
  }
  .carousel{
    &__layout{
      justify-content: flex-start;
      top: 15%
    }

    &__nav{

    }
  }


}
