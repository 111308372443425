$brand_color_var1_1: #14120f;
$brand_color_var1_2: #45C7A6;
$brand_color_var1_3: #ffffff;

$brand_color_var1_4: #dc631c;
$brand_color_var1_4_hover: #b75014;

$brand_color_var1_5: #128F70;
$brand_color_var1_6: #eeeeee;



$color_default: #000000;
$color_default_2: #000000;
$subcolor_default: #666666;